import React from "react";
import CreateRecipe_Form from "./CreateRecipe_Form";
import ScrollToTop from "../../aaHoc/ScrollToTop";
import Header from "../header/Header";
import Navigation from "../navigation/Navigation";

const CreateRecipe = () => {
  return (
    <div className="page-component create-recipe-container">
      <ScrollToTop />
      <Header />
      <Navigation />
      <CreateRecipe_Form />
    </div>
  );
};

export default CreateRecipe;
