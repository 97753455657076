import React from "react";

import ShoppingList_Checkbox from "./ShoppingList_Checkbox";

const ShoppingList_TableLine = ({ ShoppingItem, Strikedthrough }) => {
  return (
    <div
      className={
        Strikedthrough
          ? "strikedthrough table-line-shoppinglist"
          : "table-line-shoppinglist"
      }
    >
      <ShoppingList_Checkbox
        ShoppingItem={ShoppingItem}
        key={"item_" + ShoppingItem.id}
      />
      <div className="ingredient-infos">
        {ShoppingItem.ingredient_name}
        <div className="smallNoteItalic">{ShoppingItem.ingredient_classif}</div>
      </div>
      <div className="ingredient-quantity">
        {ShoppingItem.total_quantity + " " + ShoppingItem.unit_measure}
      </div>
    </div>
  );
};

export default ShoppingList_TableLine;
