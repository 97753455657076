import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setWeekmenuNumberofpeople } from "../../action/list.action";
import Meals_QuantityIncrement from "./Meals_QuantityIncrement";

const Meals_Checkbox = ({ meal }) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const mealId = meal.id_meal;
  const defaultNumberOfPeople = meal.number_of_people;

  useEffect(() => {
    if (window.localStorage.mealListNumberOfPeople) {
      // TODO: A MODIFIER CAR LE DISPATCH SE TRIGGER A CHAQUES ELEMENTS !
      let currentList = JSON.parse(
        window.localStorage.getItem("mealListNumberOfPeople")
      );
      dispatch(
        setWeekmenuNumberofpeople(
          JSON.parse(window.localStorage.getItem("mealListNumberOfPeople"))
        )
      );
      if (mealId in currentList) {
        setChecked(true);
      } else {
        setChecked(false);
      }
    }
  }, [mealId]);

  const idChecker = (id) => {
    let currentList = {};
    if (window.localStorage.mealListNumberOfPeople) {
      currentList = JSON.parse(window.localStorage.mealListNumberOfPeople);
    }
    if (currentList) {
      if (id in currentList) {
        delete currentList[id];
        window.localStorage.mealListNumberOfPeople =
          JSON.stringify(currentList);
        setChecked(false);
      } else {
        currentList[id] = defaultNumberOfPeople;
        window.localStorage.mealListNumberOfPeople =
          JSON.stringify(currentList);
        setChecked(true);
      }
    } else {
      currentList[id] = defaultNumberOfPeople;
      window.localStorage.mealListNumberOfPeople = JSON.stringify(currentList);
      setChecked(true);
    }
    dispatch(
      setWeekmenuNumberofpeople(
        JSON.parse(window.localStorage.mealListNumberOfPeople)
      )
    );
  };

  return (
    <div className="table-line-checkbox">
      <div className="checkbox-container">
        <input
          type="checkbox"
          className="round-checkbox"
          onChange={() => idChecker(mealId)}
          checked={checked}
        />
      </div>
      <div>
        <div className="meal-name">{meal.meal_name}</div>
        <Meals_QuantityIncrement
          key={meal.id_meal}
          defaultNumberOfPeople={defaultNumberOfPeople}
          meal={meal}
        />
      </div>
    </div>
  );
};

export default Meals_Checkbox;
