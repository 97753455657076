import React, { useEffect, useRef, useState } from "react";
import LogoutButton from "../logout-button/LogoutButton";
import { useNavigate } from "react-router-dom";

const DropdownMenu = () => {
  const navigate = useNavigate();
  const [displayDropdown, setDisplayDropdown] = useState(false);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDisplayDropdown(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  return (
    <div
      ref={wrapperRef}
      className="header-dropdownmenu-container bi bi-list"
      onClick={() => {
        setDisplayDropdown(!displayDropdown);
      }}
    >
      {displayDropdown ? (
        <div className="header-dropdownmenu-content">
          <div
            onClick={() => {
              navigate("/comments-to-dev");
            }}
            className="dropdownmenu-element"
          >
            Commentaire au developpeur
          </div>
          <div
            onClick={() => {
              navigate("/subscribe-success");
            }}
            className="dropdownmenu-element"
          >
            Besoin d'aide ?
          </div>
          <div
            onClick={() => {
              navigate("/share-family");
            }}
            className="dropdownmenu-element"
          >
            Partager son profil
          </div>
          <div
            className="font-color3 dropdownmenu-element"
            onClick={() => {
              window.location.href = "/install-app";
            }}
          >
            {" "}
            Installer l'application
          </div>
          <div className="dropdownmenu-element">
            <LogoutButton />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default DropdownMenu;
