import { combineReducers } from "redux";
import {
  reducer_WeekmenuNumberOfPeople,
  reducer_WeekmenuNumberOfPeople_New,
  listReducer_ShoppingList,
  boolReducer_ConnectionError,
} from "./list.reducer";

export default combineReducers({
  reducer_WeekmenuNumberOfPeople,
  reducer_WeekmenuNumberOfPeople_New,
  listReducer_ShoppingList,
  boolReducer_ConnectionError,
});
