import SERVER_ADDRESS from "../../aaHoc/global";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setBoolConnectionError,
  setListCheckedShoppinglist,
} from "../../action/list.action";
import axios from "axios";

const ShoppingList_Checkbox = ({ ShoppingItem }) => {
  const [initialized, setInitialized] = useState(false);
  const [checked, setChecked] = useState(ShoppingItem.checked);
  const ingredientId = ShoppingItem.id_ingredient;
  const itemId = ShoppingItem.id;

  const dispatch = useDispatch();
  const checkedShoppingList = useSelector(
    (state) => state.listReducer_ShoppingList.listCheckedShoppinglist
  );

  useEffect(() => {
    if (initialized) {
    } else {
      setInitialized(true);
    }
  }, []);

  async function handleChecked() {
    if (checkedShoppingList.includes(itemId)) {
      dispatch(
        setListCheckedShoppinglist(
          checkedShoppingList.filter((meal) => meal !== itemId)
        )
      );
      return checkedShoppingList.filter((meal) => meal !== itemId);
    } else {
      dispatch(setListCheckedShoppinglist([...checkedShoppingList, itemId]));
      return [...checkedShoppingList, itemId];
    }
  }

  const handleUpdateDatabase = async (listCheckedId) => {
    axios
      .put(SERVER_ADDRESS + "/api/shoppinglist/update-checked", {
        listCheckedId: listCheckedId,
        checked: listCheckedId.includes(itemId),
      })
      .then((res) => {
        dispatch(setListCheckedShoppinglist(res.data));
        dispatch(setBoolConnectionError(false));
      })
      .catch((err) => {
        dispatch(setBoolConnectionError(true));
      });
  };

  return (
    <div className="checkbox-container">
      <input
        type="checkbox"
        className="round-checkbox"
        onChange={async () => {
          const listCheckdId = await handleChecked();
          handleUpdateDatabase(listCheckdId);
        }}
        checked={checkedShoppingList.includes(itemId)}
      />
    </div>
  );
};

export default ShoppingList_Checkbox;
