import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import shoppinglistPhone from "../../assets/homepage/shoppinglist-phone.jpg";
import bannerImage from "../../assets/homepage/homepage-banner.png";
import Home_Divider from "./Home_Divider";

const Home_Landingarea = () => {
  return (
    <section id="home-landing-area">
      <div className="home-quickdescription-container">
        <Row>
          <div className="photo-banner">
            <img src={bannerImage} alt="banner" />
          </div>
        </Row>
        <Row>
          <Col>
            <div className="home-quickdescription-text">
              <p className="big-font">
                Créez et partagez vos listes de courses facilement
              </p>
              <p className="font-color2 font-classic">
                Une application facile à utiliser pour vous simplifier la vie
              </p>
            </div>
            <div
              className="squared-midtext-button home-connectbutton back-color2 font-color0"
              onClick={() => {
                window.location.href = "/shopping-list";
              }}
            >
              Essayez dès maintenant
            </div>
          </Col>
          <Col>
            <div className="photo-container">
              <img
                className="phone-image"
                src={shoppinglistPhone}
                alt="phone"
              />
            </div>
          </Col>
        </Row>
      </div>
      <Home_Divider />
    </section>
  );
};

export default Home_Landingarea;
