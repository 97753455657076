import React, { useEffect, useState } from "react";
import AutoCompleteIngredient from "../../aaHoc/AutoCompleteIngredient";

const CreateRecipe_Form_IngredientLine = ({
  index,
  listRecipeIngredients,
  handleUpdateListRecipeIngredients,
}) => {
  const [ingredientCharacteristics, setIngredientCharacteristics] = useState(
    {}
  );
  const [ingredientLineError, setIngredientLineError] = useState(false);
  const [ingredientName, setIngredientName] = useState("");

  /* CHARGEMENT INITIAL EN CAS D'UPDATE DE LA RECETTE */
  useEffect(() => {
    if (listRecipeIngredients) {
      setIngredientCharacteristics(listRecipeIngredients);
    }
  }, [listRecipeIngredients]);

  /* PERMET DE GERER LE RETOUR DU COMPOSANT AUTOCOMPLETE ET L'INTEGRER DANS IngredientCharacteristics */
  useEffect(() => {
    if (ingredientName !== "")
      handleInputChange({
        ...ingredientCharacteristics,
        ingredient_name: ingredientName,
      });
  }, [ingredientName]);

  const handleInputChange = (dictCharacteristics) => {
    setIngredientCharacteristics(dictCharacteristics);
    handleUpdateListRecipeIngredients(index, dictCharacteristics);
    if (
      dictCharacteristics?.ingredient_name === undefined ||
      dictCharacteristics?.ingredient_name === ""
    ) {
      setIngredientLineError(true);
    } else {
      setIngredientLineError(false);
    }
  };

  const handleResetInput = () => {
    setIngredientCharacteristics("");
    handleInputChange({});
    setIngredientLineError(false);
  };
  return (
    <div>
      <div className="ingredientlist-input-area">
        {ingredientLineError ||
        (ingredientCharacteristics.ingredient_name && ingredientName === "") ? (
          <div
            className="deleteitem-button bi bi-x-circle-fill"
            onClick={() => handleResetInput()}
          ></div>
        ) : (
          <div className="hidden-element deleteitem-button bi bi-x-circle-fill"></div>
        )}
        <div
          onChange={(e) =>
            handleInputChange({
              ...ingredientCharacteristics,
              ingredient_name: e.target.value.replace(/"/g, "'"),
            })
          }
        >
          <AutoCompleteIngredient
            strPlaceholder={`Ingredient ${index + 1}`}
            defaultValue={ingredientCharacteristics?.ingredient_name}
            setIngredientName={setIngredientName}
          />
        </div>
        <input
          type="text"
          className="input"
          placeholder={`Qté ${index + 1}`}
          value={ingredientCharacteristics.quantity || ""}
          onChange={(e) =>
            handleInputChange({
              ...ingredientCharacteristics,
              quantity: e.target.value.replace(/"/g, "'"),
            })
          }
        />
        <select
          value={ingredientCharacteristics.id_unit_measure || ""}
          className="input select-input"
          onChange={(e) =>
            handleInputChange({
              ...ingredientCharacteristics,
              id_unit_measure: e.target.value.replace(/"/g, "'"),
            })
          }
        >
          <option value="1">Grammes</option>
          <option value="2">Portion(s)</option>
        </select>
      </div>
      {ingredientLineError && (
        <p style={{ color: "red" }}>Le champ Ingredient est requis.</p>
      )}
    </div>
  );
};

export default CreateRecipe_Form_IngredientLine;
