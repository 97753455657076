import React from "react";
import Meals_Table from "./Meals_Table";
import Header from "../header/Header";
import Navigation from "../navigation/Navigation";

export default function Meals({}) {
  return (
    <div className="page-component meals-container">
      <Header />
      <Navigation />
      <Meals_Table />
    </div>
  );
}
