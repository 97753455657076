import React from "react";

import ShoppingList_Table from "./ShoppingList_Table";
import ShoppingList_AddItemButton from "./buttons/ShoppingList_AddItemButton";
import ShoppingList_ResetButton from "./buttons/ShoppingList_ResetButton";
import ShoppingList_RefreshButton from "./buttons/ShoppingList_RefreshButton";
import Header from "../header/Header";
import Navigation from "../navigation/Navigation";
import ScrollToTop from "../../aaHoc/ScrollToTop";

const ShoppingList = () => {
  return (
    <div className="page-component shopping-list-container">
      <ScrollToTop />
      <Header />
      <Navigation />
      <ShoppingList_Table />
      <div className="shoppinglist-interact-buttons">
        <ShoppingList_ResetButton />
        {/* <ShoppingList_RefreshButton /> */}
        <ShoppingList_AddItemButton />
      </div>
    </div>
  );
};

export default ShoppingList;
