import SERVER_ADDRESS from "../../aaHoc/global";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import AutoCompleteIngredient from "../../aaHoc/AutoCompleteIngredient";

const ShoppingList_AddItemModal = ({ handleClose }) => {
  const [show, setShow] = useState(true);
  const [ingredientName, setIngredientName] = useState("");
  const [ingredientQuantity, setIngredientQuantity] = useState("");
  const [ingredientUnitMeasure, setIngredientUnitMeasure] =
    useState(1); /* 1 pour Grammes, valeur définie plus bas dans le select */

  const [familyStats, setFamilyStats] = useState();
  const [familyHasStats, setFamilyHasStats] = useState(false);
  useEffect(() => {
    axios.get(SERVER_ADDRESS + "/api/shoppingList/family-stats").then((res) => {
      setFamilyStats(
        res.data.filter((item) => item.in_current_shoppinglist === 0)
      );
      if (res.data.length > 0) {
        setFamilyHasStats(true);
      }
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ingredientName !== "") {
      axios
        .post(SERVER_ADDRESS + "/api/shoppingList/add-item", {
          ingredientName,
          ingredientQuantity,
          ingredientUnitMeasure,
        })
        .then((res) => {
          setShow(false);
          handleClose();
        });
    }
  };

  function handleAddQuickitem(index) {
    const ingredientName_Quickadd = familyStats[index].ingredient_name;
    const ingredientQuantity_Quickadd =
      "1"; /* Set as string to be identified in backend */
    const ingredientUnitMeasure_Quickadd = 2; /* Portion(s) */

    axios
      .post(SERVER_ADDRESS + "/api/shoppingList/add-item", {
        ingredientName: ingredientName_Quickadd,
        ingredientQuantity: ingredientQuantity_Quickadd,
        ingredientUnitMeasure: ingredientUnitMeasure_Quickadd,
      })
      .then(
        setFamilyStats((oldValues) => {
          return oldValues.filter((_, i) => i !== index);
        })
      );
  }
  return (
    <>
      <Modal
        dialogClassName="additem-modal"
        show={show}
        onHide={() => {
          setShow(false);
          handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="text-center">Ajouter un nouvel article</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            onChange={(e) => {
              setIngredientName(e.target.value.replace(/"/g, "'").trim());
            }}
          >
            <AutoCompleteIngredient
              strPlaceholder="Nom de l'article"
              setIngredientName={setIngredientName}
            />
          </div>
          <div className="quantity-inputarea">
            <input
              type="number"
              placeholder="Quantité (Facultatif)"
              className="input"
              onChange={(e) =>
                setIngredientQuantity(e.target.value.replace(/"/g, "'"))
              }
              value={ingredientQuantity}
            />
            <select
              name="id_unit_measure"
              className="input"
              onChange={(e) =>
                setIngredientUnitMeasure(e.target.value.replace(/"/g, "'"))
              }
            >
              <option value="1">Grammes</option>
              <option value="2">Portion(s)</option>
            </select>
          </div>
          <div
            className="additem-submit-button btn btn-warning"
            onClick={(e) => handleSubmit(e)}
          >
            Ajouter l'article
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="quickadd-container">
            {!familyHasStats ? (
              <p>
                <i>
                  Vos ajouts récents/fréquents seront affichés ici lors de vos
                  prochaines courses, une fois que vous aurez ajouté de nouveaux
                  articles.
                </i>
              </p>
            ) : (
              ""
            )}
            {familyStats?.slice(0, 8).map((item, index) => (
              <div
                key={item.ingredient_name}
                className="quickadd-item"
                onClick={() => {
                  handleAddQuickitem(index);
                }}
              >
                <i className="bi bi-plus-lg"></i> {item.ingredient_name}
              </div>
            ))}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ShoppingList_AddItemModal;
