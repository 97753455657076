import React, { useState } from "react";

import Meals_Checkbox from "./Meals_Checkbox";
import Meals_Details from "./Meals_Details";
import { useNavigate } from "react-router-dom";

const Meals_TableLine = ({ meal }) => {
  const navigate = useNavigate();
  const [showMealDetails, setShowMealDetails] = useState(false);

  const handleClose = () => {
    navigate("/meals");
    setShowMealDetails(false);
  };
  return (
    <div className="table-line-meals">
      <Meals_Checkbox key={meal.id_meal} meal={meal} />
      <div className="meal-buttons">
        <div
          className="button-view"
          onClick={() => {
            setShowMealDetails(true);
          }}
        >
          <i className="bi bi-eye"></i>
        </div>
        <span className="mealdetails-container" id={meal.meal_name}>
          {showMealDetails && (
            <Meals_Details
              key={meal.id_meal}
              meal={meal}
              handleClose={handleClose}
            />
          )}
        </span>
      </div>
    </div>
  );
};

export default Meals_TableLine;
