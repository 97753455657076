import SERVER_ADDRESS from "../../aaHoc/global";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ShareFamily_Create from "./ShareFamily_Create";
import ShareFamily_Share from "./ShareFamily_Share";
import ScrollToTop from "../../aaHoc/ScrollToTop";
import Header from "../header/Header";
import Navigation from "../navigation/Navigation";

const ShareFamily = () => {
  const [familyData, setFamilyData] = useState("");
  const [isDefaultFamily, setIsDefaultFamily] = useState("");

  useEffect(() => {
    axios.get(SERVER_ADDRESS + `/api/family`).then((res) => {
      setFamilyData(res.data);
      setIsDefaultFamily(res.data.default_family);
    });
  }, []);
  return (
    <div className="page-component">
      <ScrollToTop />
      <Header />
      <Navigation />
      {isDefaultFamily ? (
        <ShareFamily_Create />
      ) : (
        <ShareFamily_Share familyData={familyData} />
      )}
    </div>
  );
};

export default ShareFamily;
