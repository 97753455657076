import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import FadeInEffect from "../effects/FadeInEffect";
const Home_Whyusing = () => {
  return (
    <section id="home-section2">
      <div>
        <h1>Pourquoi utiliser MyWeeklyMenu ?</h1>
        <Row>
          <Col>
            <FadeInEffect>
              <div className="home-advantage-box">
                <h2>Gagnez du temps</h2>
                <div className="divider"></div>
                <p>
                  Avec MyWeeklyMenu, ne perdez plus de temps à écrire vos listes
                  de courses manuellement! Votre liste sera{" "}
                  <b>générée automatiquement</b>, et <b>triée</b> par type
                  d'articles (Viandes, légumes, surgelés, etc...)
                </p>
              </div>
            </FadeInEffect>
          </Col>
          <Col>
            <FadeInEffect>
              <div className="home-advantage-box">
                <h2>Evitez le gaspillage</h2>
                <div className="divider"></div>
                <p>
                  Terminé les aliments qui périment dans le frigo.
                  <b> Vous connaissez exactement la quantité nécéssaire</b> aux
                  plats que vous allez préparer.
                </p>
              </div>
            </FadeInEffect>
          </Col>
          <Col>
            <FadeInEffect>
              <div className="home-advantage-box">
                <h2>Economisez de l'argent</h2>
                <div className="divider"></div>
                <p>
                  En préparant vos listes de courses <b>en avance</b>, vous
                  évitez d'acheter des <b>articles superflu</b>. Vous pouvez
                  aussi selectionner votre <b>magasin favori</b> pour connaitre
                  vos recettes en promotion actuellement !
                </p>
              </div>
            </FadeInEffect>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Home_Whyusing;
