import SERVER_ADDRESS from "../../aaHoc/global";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const WeekMenu = ({}) => {
  const [mealList, setMealList] = useState([]);

  const [weekMenu, setWeekMenu] = useState([]);
  const dictReducerWeekmenuNumberOfPeople = useSelector(
    (state) => state.reducer_WeekmenuNumberOfPeople.dictWeekMenuNumberOfPeople
  );
  const dictReducerWeekmenuNumberOfPeople_New = useSelector(
    (state) =>
      state.reducer_WeekmenuNumberOfPeople_New.dictWeekMenuNumberOfPeople_New
  );

  const handleClearWeekmenu = () => {
    axios
      .delete(SERVER_ADDRESS + "/api/shoppingList/clear", {
        data: {
          onlyMealsIngredients: true,
        },
      })
      .then((res) => {
        axios.put(SERVER_ADDRESS + "/api/weekmenu/clear", {});
        window.localStorage.mealListNumberOfPeople = JSON.stringify({});
        window.localStorage.mealListNumberOfPeople_New = JSON.stringify({});
        window.localStorage.checkedShoppingList = "";
        window.location.reload("");
      });
  };
  useEffect(() => {
    if (Object.keys(dictReducerWeekmenuNumberOfPeople).length > 0) {
      setWeekMenu(dictReducerWeekmenuNumberOfPeople);
    } else {
      axios.get(SERVER_ADDRESS + "/api/weekmenu/get").then((res) => {
        if (res.data !== null) {
          setWeekMenu(res.data);
        } else {
        }
      });
    }
    axios.get(SERVER_ADDRESS + "/api/meals").then((res) => {
      setMealList(res.data);
    });
  }, [dictReducerWeekmenuNumberOfPeople]);

  return (
    <div className="weekmenu-container">
      <div className="weekmenu-content">
        <div className="weekmenu-title">
          <h2>
            {Object.keys(dictReducerWeekmenuNumberOfPeople).length === 0
              ? "Actuel :"
              : "Nouveau :"}
          </h2>
        </div>
        {mealList
          ?.filter((meal) => {
            if (weekMenu) {
              if (meal.id_meal in weekMenu) {
                return meal;
              }
            }
          })
          ?.map((meal, index) => {
            // TODO: Mettre a jour pour que la quantité ne se modifie que si on est sur une nouvelle liste ( Et donc pas la précédente qui est en DB)
            let numberOfPeople = weekMenu[meal.id_meal];
            if (Object.keys(dictReducerWeekmenuNumberOfPeople).length === 0) {
              numberOfPeople = weekMenu[meal.id_meal];
            } else if (
              dictReducerWeekmenuNumberOfPeople_New &&
              meal.id_meal in dictReducerWeekmenuNumberOfPeople_New
            ) {
              numberOfPeople =
                dictReducerWeekmenuNumberOfPeople_New[meal.id_meal];
            }
            return (
              <li key={meal.id_meal}>
                {meal.meal_name}
                {" (" +
                  numberOfPeople +
                  (numberOfPeople === 1 ? " p.)" : " p.)")}
              </li>
            );
          })}
        <div
          className="clear-weekmenu-button"
          onClick={() => {
            handleClearWeekmenu();
          }}
        >
          <i className="bi bi-trash-fill"></i>
        </div>
      </div>
    </div>
  );
};

export default WeekMenu;
