import SERVER_ADDRESS from "../../aaHoc/global";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";

import ScrollToTop from "../../aaHoc/ScrollToTop";
import Header from "../header/Header";

const ResetPassword = () => {
  const navigate = useNavigate();

  const { resetToken } = useParams();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState("");
  const [passwordConfirmed, setPasswordConfirmed] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmed, setShowPasswordConfirmed] = useState(false);

  const [connectionError, setConnectionError] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (resetToken) {
      axios
        .put(SERVER_ADDRESS + "/api/user/password/reset", {
          resetToken,
          password,
          passwordConfirmed,
        })
        .then((res) => {
          setShowModal(true);
          setConnectionError(false);
        })
        .catch(() => {
          setConnectionError(true);
        });
    } else {
      axios
        .put(SERVER_ADDRESS + "/api/user/password/generate-token", {
          email,
        })
        .then((res) => {
          setShowModal(true);
          setConnectionError(false);
        })
        .catch(() => {
          setConnectionError(true);
        });
    }
  };
  return (
    <div className="page-component reset-page">
      <ScrollToTop />
      <Header />
      <div className="login-container">
        <div>
          <div className="login-input">
            <h1 className="centered">Réinitialiser le mot de passe</h1>
            <div className="divider"></div>
            {resetToken ? (
              <div>
                <div className="input">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Mot de passe"
                    className="input-password"
                    onChange={(e) =>
                      setPassword(e.target.value.replace(/"/g, "'"))
                    }
                    value={password}
                  />
                  <i
                    className={
                      showPassword
                        ? "input-password-toggle bi bi-eye-slash-fill"
                        : "input-password-toggle bi bi-eye-fill"
                    }
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  ></i>
                </div>
                <div className="input">
                  <input
                    type={showPasswordConfirmed ? "text" : "password"}
                    placeholder="Confirmez le mot de passe"
                    className="input-password"
                    onChange={(e) =>
                      setPasswordConfirmed(e.target.value.replace(/"/g, "'"))
                    }
                    value={passwordConfirmed}
                  />
                  <i
                    className={
                      showPasswordConfirmed
                        ? "input-password-toggle bi bi-eye-slash-fill"
                        : "input-password-toggle bi bi-eye-fill"
                    }
                    onClick={() => {
                      setShowPasswordConfirmed(!showPasswordConfirmed);
                    }}
                  ></i>
                </div>
              </div>
            ) : (
              <input
                type="text"
                className="input"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value.replace(/"/g, "'"))}
                value={email}
              />
            )}
            {connectionError && (
              <p className="error-message">
                Un problème est survenu. Veuillez réessayer.
              </p>
            )}
          </div>
          <div className="login-button-area">
            <div
              className="rounded-shorttext-button back-color2 font-color0 font-bold centered alldir-shadows"
              onClick={handleSubmit}
            >
              Réinitialiser
            </div>
          </div>
        </div>
      </div>
      <Modal
        centered
        show={showModal}
        scrollable={true}
        onHide={() => {
          {
            resetToken ? navigate("/login") : setShowModal(false);
          }
        }}
      >
        <Modal.Header
          closeButton
          bsPrefix="details-modal-title-area"
        ></Modal.Header>
        <Modal.Body>
          {resetToken ? (
            <div>
              <p className="centered">
                <b>Votre mot de passe a été correctement modifié.</b>
              </p>
              <p className="centered">
                Vous allez être redirigé vers la page de connection.
              </p>
            </div>
          ) : (
            <div>
              <p className="centered">
                <b>Un lien de réinitialisation à été envoyé à {email}.</b>
              </p>
              <p className="centered">
                Suivez les instructions pour réinitialiser votre mot de passe.
                (N'oubliez pas de vérifier vos spam si vous n'avez rien reçu)
              </p>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ResetPassword;
