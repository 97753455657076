import { motion } from "framer-motion";

const Ztest = () => {
  return (
    <div>
      <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
        {" "}
        Animated Div
      </motion.div>
    </div>
  );
};

export default Ztest;
