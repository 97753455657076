import React from "react";
import Home_Divider from "./Home_Divider";
import SlideEffect from "../effects/SlideEffect";

const Home_Howitworks = () => {
  return (
    <section id="home-section3">
      <div className="howitworks-container font-handwritten">
        <h1 className="font-handwritten">Comment ça marche ?</h1>
        <SlideEffect positioninqueue={1} startposition={"-100%"}>
          <div className="slide-left howitworks-box">
            1. <b>Créez vos recettes</b> favorites dans l'application, à
            n'importe quel moment
          </div>
        </SlideEffect>
        <SlideEffect positioninqueue={2} startposition={"100%"}>
          <div className="slide-right howitworks-box">
            2. Choisissez ce que vous allez <b>préparer cette semaine</b>
          </div>
        </SlideEffect>
        <SlideEffect positioninqueue={2} startposition={"-100%"}>
          <div className="slide-left howitworks-box">
            3. MyWeeklyMenu prépare votre liste de courses{" "}
            <b>en quelques secondes !</b> Si vous avez plusieurs recettes avec
            les mêmes ingredients, l'application se charge de les{" "}
            <b>regrouper en une seule ligne !</b>
          </div>
        </SlideEffect>
        <SlideEffect positioninqueue={2} startposition={"100%"}>
          <div className="slide-right howitworks-box">
            4. Rajoutez des <b>articles supplémentaires</b> si besoin (Sel,
            lait, oeufs, éponges, etc ...)
          </div>
        </SlideEffect>
        <SlideEffect positioninqueue={3} startposition={"-100%"}>
          <div className="slide-left howitworks-box">
            5. Faites vos courses <b>efficacement</b> ! Seul ou à plusieurs,
            grâce à la fonction de <b>partage de votre liste de courses</b>.
          </div>
        </SlideEffect>
        <Home_Divider />
        <div
          className="squared-midtext-button home-connectbutton back-color2 font-color0"
          onClick={() => {
            window.location.href = "/shopping-list";
          }}
        >
          Essayez dès maintenant
        </div>
      </div>
    </section>
  );
};

export default Home_Howitworks;
