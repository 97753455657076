import React from "react";

import { NavLink } from "react-router-dom";

import imgW from "../../assets/app-img/imageW2-noback.png";

const Navigation = () => {
  return (
    <div className="navigation">
      <div className="nav-link-container">
        <NavLink
          to="/meals"
          className={(nav) => (nav.isActive ? "navlink nav-active" : "navlink")}
        >
          <li>
            <i className="bi bi-book"></i>
          </li>
          <li className="navbar-smalltext">Mes Recettes</li>
        </NavLink>
      </div>
      <div className="nav-link-container">
        <NavLink
          to="/shopping-list"
          className={(nav) => (nav.isActive ? "navlink nav-active" : "navlink")}
        >
          <li>
            <img className="img-w" src={imgW} alt="" />
          </li>
          <li className="navbar-smalltext">Liste de courses</li>
        </NavLink>
      </div>
      <div className="nav-link-container">
        <NavLink
          to="/create-recipe"
          className={(nav) => (nav.isActive ? "navlink nav-active" : "navlink")}
        >
          <li>
            <i className="bi bi-journal-plus"></i>
          </li>
          <li className="navbar-smalltext">Créer recette</li>
        </NavLink>
      </div>
    </div>
  );
};

export default Navigation;
