import SERVER_ADDRESS from "../../aaHoc/global";
import React, { useState } from "react";

const ShareFamily_Share = ({ familyData }) => {
  const [copied, setCopied] = useState(false);
  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      SERVER_ADDRESS + "/join-family/" + familyData.share_token
    );
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };
  return (
    <div>
      <h1 className="sharefamily-textarea">
        <b>{familyData.family_name}</b>
      </h1>
      <div className="divider"></div>
      <p className="sharefamily-textarea">
        Invite des utilisateurs à rejoindre ta famille
      </p>
      <p className="sharefamily-textarea">
        Clique sur le bouton ci-dessous pour copier le lien de partage, et
        l'envoyer à tes proches !
      </p>
      <div
        className="rounded-midtext-button back-color2 font-color0 centered font-bold alldir-shadows"
        onClick={() => {
          handleCopyLink();
        }}
      >
        Inviter à rejoindre ma famille
      </div>
      {copied && <div className="copy-message">Lien copié !</div>}
    </div>
  );
};

export default ShareFamily_Share;
