import React from "react";
import { useAuth } from "../../utils/auth";
import { useNavigate } from "react-router-dom";

const LogoutButton = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const handleLogout = () => {
    auth.logout();
    navigate("/");
  };
  return (
    <div className="logout_button_area ">
      <button className="btn btn-danger" onClick={() => handleLogout()}>
        <i className="bi bi-power"></i>
      </button>
    </div>
  );
};
export default LogoutButton;
