import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import iosAddToHomescreen from "../../assets/tuto-image/apple-add-to-homescreen-button.png";

const InstallApp_Button_Apple = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      <div
        className="rounded-midtext-button font-color0 back-color2 font-bold"
        id="install-apple"
        onClick={() => {
          setShowModal(true);
        }}
      >
        Installer l'application (iOS)
      </div>

      <Modal
        dialogClassName="apple-install-modal"
        show={showModal}
        scrollable={true}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Body>
          <div className="flex-div">
            <div>
              <i class="applemodal-plus-img bi bi-plus-square-fill"></i>
            </div>
            <div>
              Cliquez sur le bouton{" "}
              <img
                className="img-apple-modal"
                src={iosAddToHomescreen}
                alt="ios-download-1"
              />
              , et ensuite sur le bouton "<b>Sur l'écran d'acceuil</b>"
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default InstallApp_Button_Apple;
