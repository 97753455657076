import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { setWeekmenuNumberofpeople_New } from "../../action/list.action";

const Meals_QuantityIncrement = ({ meal, defaultNumberOfPeople }) => {
  const dispatch = useDispatch();

  const [numberOfPeople, setNumberOfPeople] = useState(defaultNumberOfPeople);
  const mealId = meal.id_meal;

  let currentList = {};
  useEffect(() => {
    const dict = window.localStorage.mealListNumberOfPeople_New;
    if (dict && Object.keys(dict).length !== 0) {
      // TODO: A MODIFIER CAR LE DISPATCH SE TRIGGER A CHAQUES ELEMENTS !
      currentList = JSON.parse(dict);
      if (mealId in currentList) {
        dispatch(setWeekmenuNumberofpeople_New(currentList));
        setNumberOfPeople(currentList[mealId]);
      }
    }
  }, [mealId]);

  const handleChangeNumberOfPeople = (newNumberOfPeople) => {
    if (window.localStorage.mealListNumberOfPeople_New) {
      currentList = JSON.parse(window.localStorage.mealListNumberOfPeople_New);
    }
    if (mealId in currentList) {
      if (newNumberOfPeople === defaultNumberOfPeople) {
        delete currentList[mealId];
      } else {
        currentList[mealId] = newNumberOfPeople;
      }
    } else {
      currentList[mealId] = newNumberOfPeople;
    }
    window.localStorage.mealListNumberOfPeople_New =
      JSON.stringify(currentList);
    setNumberOfPeople(newNumberOfPeople);
    dispatch(
      setWeekmenuNumberofpeople_New(
        JSON.parse(window.localStorage.mealListNumberOfPeople_New)
      )
    );
  };

  const increment = () => {
    handleChangeNumberOfPeople(numberOfPeople + 1);
  };
  const decrement = () => {
    if (numberOfPeople !== 1) {
      handleChangeNumberOfPeople(numberOfPeople - 1);
    }
  };
  return (
    <div className="increment-buttons-area">
      <p className="num-pers">Portions: </p>
      <div className="minus-button-container">
        <i
          onClick={decrement}
          className="minus-button bi bi-dash-circle-fill"
        ></i>
      </div>
      <div className="nop-input-container">
        <input
          placeholder="Nombre de personnes"
          className="round-buttons"
          value={numberOfPeople}
          onChange={(e) => {
            handleChangeNumberOfPeople(e.target.value.replace(/"/g, "'"));
          }}
          style={{ width: "30px", textAlign: "center" }}
          readOnly
        />
      </div>
      <i onClick={increment} className="plus-button bi bi-plus-circle-fill"></i>
    </div>
  );
};

export default Meals_QuantityIncrement;
