import ReactDOM from 'react-dom/client';

// REDUX
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from "./reducer/index.jsx";
import App from './App';
import "./styles/index.scss";

import Container from "react-bootstrap/Container";
const store = configureStore({
  reducer: rootReducer,
  devTools: true,
})
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Container>
      <App />
    </Container>
  </Provider>
);
