import React, { useEffect } from "react";
import { useAuth } from "../utils/auth";

const RedirectMainPage = () => {
  const auth = useAuth();
  useEffect(() => {
    if (auth.jwtToken) {
      window.location.href = "/shopping-list";
    } else {
      window.location.href = "/home";
    }
  }, []);
  return <div></div>;
};

export default RedirectMainPage;
