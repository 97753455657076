import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setWeekmenuNumberofpeople } from "../../../action/list.action";
import SERVER_ADDRESS from "../../../aaHoc/global";
import axios from "axios";
import { Modal } from "react-bootstrap";

const ShoppingList_ResetButton = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const handleResetShoppinglist = () => {
    setShowModal(false);
    axios
      .delete(SERVER_ADDRESS + "/api/shoppingList/clear", {
        data: {
          onlyMealsIngredients: false,
        },
      })
      .then((res) => {
        dispatch(setWeekmenuNumberofpeople([]));
        window.localStorage.mealListNumberOfPeople = JSON.stringify({});
        window.localStorage.checkedShoppingList = "";
        axios.put(SERVER_ADDRESS + "/api/weekmenu/clear", {});
        window.location.reload("");
      });
  };

  return (
    <div className="button-resetshoppinglist-container bottomright-floatingbutton-container">
      <i
        className="button-resetshoppinglist bi bi-trash"
        onClick={() => {
          setShowModal(true);
          /* handleResetShoppinglist(); */
        }}
      ></i>
      <Modal
        centered
        show={showModal}
        scrollable={true}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Header
          closeButton
          bsPrefix="details-modal-title-area"
        ></Modal.Header>
        <Modal.Body>
          <p>Etes-vous sur de vouloir réinitialiser votre liste de courses ?</p>
        </Modal.Body>
        <Modal.Footer className="confirmmodal-footer centered">
          <button
            className="confirmmodal-button-yes btn btn-warning"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Non
          </button>
          <div
            className="confirmmodal-button-yes btn btn-danger"
            onClick={() => {
              handleResetShoppinglist();
            }}
          >
            Oui
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ShoppingList_ResetButton;
