import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';

import Meals from './components/meals/Meals';
import ShoppingList from './components/shopping-list/ShoppingList';
import CreateRecipe from './components/create-recipe/CreateRecipe';
import Login from './components/login/Login';
import RequireAuth from './utils/RequireAuth';
import { AuthProvider } from './utils/auth';
import Subscribe from './components/subscribe/Subscribe';
import SubscribeSuccess from './components/subscribe/SubscribeSuccess';
import CommentsToDev from './components/comments-to-dev/CommentsToDev';
import InstallApp from './components/install-app/InstallApp';

import Ztest from './components/ztest/Ztest';
import JoinFamily from './components/join-family/JoinFamily';
import ShareFamily from './components/share-family/ShareFamily';
import RedirectMainPage from './aaHoc/RedirectMainPage';
import Home from './components/home/Home';
import ResetPassword from './components/login/ResetPassword';

const App = () => {
  return (
    <div>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            {/* HOMEPAGE ROUTES */}
            <Route path="/home" element={<Home />} />

            {/* APP ROUTES */}
            <Route path="/meals/*" element={
              <RequireAuth>
                <Meals />
              </RequireAuth>} />
            <Route path="/shopping-list" element={
              <RequireAuth>
                <ShoppingList />
              </RequireAuth>
            } />
            <Route path="/create-recipe" element={
              <RequireAuth>
                <CreateRecipe />
              </RequireAuth>} />
            <Route path="/create-recipe/:id" element={
              <RequireAuth>
                <CreateRecipe />
              </RequireAuth>} />
            <Route path="/share-family" element={
              <RequireAuth>
                <ShareFamily />
              </RequireAuth>} />
            <Route path="/install-app" element={
              <RequireAuth>
                <InstallApp />
              </RequireAuth>} />
            <Route path="/login" element={<Login />} />
            <Route path="/login/:shareToken" element={<Login />} />
            <Route path="/subscribe" element={<Subscribe />} />
            <Route path="/subscribe/:shareToken" element={<Subscribe />} />
            <Route path="/subscribe-success" element={<SubscribeSuccess />} />
            <Route path="/comments-to-dev" element={<CommentsToDev />} />
            <Route path="/join-family/:shareToken" element={<JoinFamily />} />
            <Route path="/reset" element={<ResetPassword />} />
            <Route path="/reset/:resetToken" element={<ResetPassword />} />
            <Route path="/ztest" element={<Ztest />} />
            <Route path="*" element={
              <RedirectMainPage />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </div>

  );
};

export default App;