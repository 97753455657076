import React from "react";
import dividerImage from "../../assets/homepage/homepage-divider.png";

const Home_Divider = () => {
  return (
    <div className="home-divider">
      <img className="home-divider-img" src={dividerImage} alt="" />
    </div>
  );
};

export default Home_Divider;
