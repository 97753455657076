import axios from "axios";
import React, { useEffect, useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import SERVER_ADDRESS from "./global";

const AutoCompleteIngredient = ({
  strPlaceholder,
  defaultValue,
  setIngredientName,
}) => {
  const [listIngredients, setListIngredients] = useState("");
  const [ingredientSuggestion, setIngredientSuggestion] = useState("");
  useEffect(() => {
    /* TODO : Peut etre qu'on pourrait mettre cela dans un onSelect de reactAutoComplete pour limiter les appels */
    /* Ou mieux : Utiliser Redux et n'appeler les ingrédients qu'une seule fois */

    async function getIngredients() {
      try {
        let newDict = {};
        await axios
          .get(SERVER_ADDRESS + "/api/ingredients/autocomplete")
          .then((res) => {
            newDict = res.data.map((item) => {
              return {
                id: item.id,
                name: item.ingredient_name,
              };
            });
          });
        setListIngredients(newDict);
      } catch (error) {
        console.error(error);
      }
    }
    getIngredients();
  }, []);

  const formatResult = (item) => {
    return (
      <>
        <span
          style={{
            display: "block",
            textAlign: "left",
          }}
        >
          {item.name}
        </span>
      </>
    );
  };
  const handleOnClear = () => {
    setIngredientName();
  };
  return (
    <div
      onChange={(e) => {
        setIngredientName(e.target.value.replace(/"/g, "'").trim());
        setIngredientSuggestion(e.target.value.replace(/"/g, "'").trim());
      }}
    >
      <ReactSearchAutocomplete
        className="autocomplete-container "
        styling={
          defaultValue
            ? {
                fontSize: "14px",
                backgroundColor: "#ebebeb",
              }
            : { fontSize: "14px" }
        } /* Font size as in root */
        placeholder={defaultValue ? defaultValue : strPlaceholder}
        items={listIngredients}
        fuseOptions={{ threshold: 0.3 }}
        showIcon={false}
        showClear={true}
        onClear={handleOnClear}
        onSelect={(item) => {
          setIngredientName(item.name.replace(/"/g, "'").trim());
        }}
        showNoResults={true}
        showNoResultsText={ingredientSuggestion}
        maxResults={5}
        formatResult={formatResult}
      />
    </div>
  );
};

export default AutoCompleteIngredient;
