import React from "react";
import recipeButtonImage from "../../assets/tuto-image/recipe-button.png";
import createRecipeButtonImage from "../../assets/tuto-image/createrecipe-button.png";
import shoppinglistButtonImage from "../../assets/tuto-image/shoppinglist-button.png";
import weekmenuButtonImage from "../../assets/tuto-image/weekmenu-button.png";
import viewdetailsButtonImage from "../../assets/tuto-image/viewdetails-button.png";
import numberofpeopleButtonImage from "../../assets/tuto-image/numberofpeople-button.png";
import plusButtonImage from "../../assets/tuto-image/plus-button.png";
import refreshButtonImage from "../../assets/tuto-image/refresh-button.png";
import deleteButtonImage from "../../assets/tuto-image/delete-button.png";
import clearweekmenuButtonImage from "../../assets/tuto-image/clearweekmenu-button.png";

import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../aaHoc/ScrollToTop";
import Header from "../header/Header";
import Navigation from "../navigation/Navigation";

const SubscribeSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="page-component subscribe-success">
      <ScrollToTop />
      <Header />
      <Navigation />
      <h1>Bienvenue sur</h1>
      <h1>MyWeeklyMenu ! </h1>
      <div className="divider"></div>
      <div className="subscribesuccess-infos-container">
        <div className="subscribesuccess-recipe-container duo-grid-right">
          <div>
            <p>
              Dans la section <b>Recettes</b>, tu trouveras l'ensemble de tes
              recettes habituelles. Il te suffit de{" "}
              <b>selectionner les recettes</b> que tu souhaites faire cette
              semaine, et de <b>générer la liste de courses</b>. Tu as la
              possibilité de voir le détail en cliquant sur le bouton{" "}
              <img
                className="img-tuto"
                src={viewdetailsButtonImage}
                alt="viewdetails-image"
              />
            </p>
            <p>
              Tu peux également <b>modifier le nombre de personnes</b> via les
              touches{" "}
              <img
                className="img-tuto"
                src={numberofpeopleButtonImage}
                alt="numberofpeople-image"
              />
              .
            </p>
          </div>
          <img
            className="img-tuto"
            src={recipeButtonImage}
            alt="recipe-image"
          />
        </div>
        <div className="subscribesuccess-shoppinglist-container duo-grid-left">
          <img
            className="img-tuto"
            src={shoppinglistButtonImage}
            alt="shoppinglist-image"
          />
          <div>
            <p>
              Dans la section <b>Liste de courses</b>, se trouve la{" "}
              <b>liste générée par MyWeeklyMenu</b>. Les quantités de chaque
              ingrédients sont additionnées, et ils sont regroupés par type afin
              de ne pas perdre de temps dans les rayons du magasin !
            </p>
            <p>
              <img
                className="img-tuto"
                src={plusButtonImage}
                alt="plus-image"
              />{" "}
              A l'aide de ce bouton, tu pourras{" "}
              <b>ajouter des articles additionnels</b> (Eponges, papier
              toilette, dentifrice, etc ...) afin de compléter ta liste de
              courses, et ne rien oublier !
            </p>
            <p>
              <img
                className="img-tuto"
                src={refreshButtonImage}
                alt="refresh-image"
              />{" "}
              Ce bouton te permet de <b>rafraichir la liste de courses</b>,
              lorsque tu fais les courses avec quelqu'un d'autre.
            </p>
            <p>
              <img
                className="img-tuto"
                src={deleteButtonImage}
                alt="delete-image"
              />{" "}
              Celui-ci te permet de{" "}
              <b>réinitialiser complètement la liste de courses</b>.
            </p>
          </div>
        </div>
        <div className="subscribesuccess-createrecipe-container duo-grid-right">
          <p>
            La section <b>Créer recette</b>, te permet de{" "}
            <b>créer de nouvelles recettes</b>. Celles-ci seront alors ajoutées
            à ta liste dans la section de Recettes.
          </p>
          <img
            className="img-tuto"
            src={createRecipeButtonImage}
            alt="createrecipe-image"
          />
        </div>
        <div className="subscribesuccess-weekmenu-container duo-grid-left">
          <img
            className="img-tuto"
            src={weekmenuButtonImage}
            alt="recipe-image"
          />
          <p>
            Le bouton <b>WeeklyMenu</b> te permet de <b>voir les plats</b> que
            tu as prévu actuellement. Dans ce panneau déroulant, le bouton
            <img
              className="img-tuto"
              src={clearweekmenuButtonImage}
              alt="clearweekmenu-image"
            />{" "}
            te permet de <b>réinitialiser ton WeeklyMenu</b> et sa liste
            d'ingrédients.
          </p>
        </div>
      </div>
      <div
        className="rounded-shorttext-button back-color2 font-color0 centered font-bold alldir-shadows"
        onClick={() => {
          navigate("/shopping-list");
        }}
      >
        C'est parti !
      </div>
    </div>
  );
};

export default SubscribeSuccess;
