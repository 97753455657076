import React from "react";
import InstallApp_Button_Android from "./InstallApp_Button_Android";
import InstallApp_Button_Apple from "./InstallApp_Button_Apple";

import iosDownload1 from "../../assets/tuto-image/ios-download-1.png";
import iosDownload2 from "../../assets/tuto-image/ios-download-2.png";
import ScrollToTop from "../../aaHoc/ScrollToTop";
import Header from "../header/Header";
import Navigation from "../navigation/Navigation";

const InstallApp = () => {
  return (
    <div className="page-component installapp-container centered">
      <ScrollToTop />
      <Header />
      <Navigation />
      <div className="installbutton-description-container">
        <p></p>
        <h1>Android / Windows</h1>
        <div className="divider"></div>
      </div>
      <div className="installapp-button-container">
        <InstallApp_Button_Android />
      </div>

      <div className="installbutton-description-container">
        <h1>iPhone / iOS</h1>
        <div className="divider"></div>
      </div>
      <div className="installapp-button-container">
        <InstallApp_Button_Apple />
      </div>
      <div>
        <p>
          Si vous utilisez <b>Chrome sur iOS</b>, ce bouton se trouve{" "}
          <b>en haut à droite</b>, a proximité de la barre d'URL comme expliqué
          ci-dessous
        </p>
        <h3>Etape 1 : Bouton de téléchargement</h3>{" "}
        <img className="ios-tuto-img" src={iosDownload1} alt="ios-download-1" />
        <h3>Etape 2 : Ajouter à l'écran d'acceuil</h3>{" "}
        <img className="ios-tuto-img" src={iosDownload2} alt="ios-download-2" />
      </div>
    </div>
  );
};

export default InstallApp;
