import React, { useEffect, useState } from "react";
import { useAuth } from "../../utils/auth";

const Home_Navigation = () => {
  const auth = useAuth();
  const [connected, setConnected] = useState(false);
  useEffect(() => {
    if (auth.jwtToken) {
      setConnected(true);
    } else {
      setConnected(false);
    }
  }, []);
  return (
    <div className="home-navbar-container">
      <h1 className="home-navbar-title">MyWeeklyMenu</h1>
      <div className="home-navbar-connectbutton-area">
        <div
          className="rounded-shorttext-button home-navbar-connectbutton back-color2 font-color0"
          onClick={() => {
            window.location.href = "/shopping-list";
          }}
        >
          {connected ? "Application" : "S'inscrire"}
        </div>
      </div>
    </div>
  );
};

export default Home_Navigation;
