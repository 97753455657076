import React, { useEffect, useState } from "react";

const BirthdayInput = ({ handleUpdateBirthday }) => {
  const [day, setDay] = useState(1);
  const [month, setMonth] = useState(1);
  const [year, setYear] = useState(2000);

  useEffect(() => {
    handleUpdateBirthday(new Date(year, month - 1, day, 0, 0, 0));
  }, [day, month, year]);

  return (
    <div className="date-input-area">
      <div className="date-input-container">
        <p className="date-label">Jour</p>
        <select
          className="input date-input font-color1"
          defaultValue={day}
          onChange={(e) => {
            setDay(e.target.value);
          }}
        >
          {Array.from({ length: 31 }, (_, index) => index + 1).map((day) => (
            <option key={day} value={day}>
              {day}
            </option>
          ))}
        </select>
      </div>
      <div className="date-input-container">
        <p className="date-label">Mois</p>
        <select
          className="input date-input font-color1"
          defaultValue={month}
          onChange={(e) => {
            setMonth(e.target.value);
          }}
        >
          {Array.from({ length: 12 }, (_, index) => index + 1).map((day) => (
            <option key={day} value={day}>
              {day}
            </option>
          ))}
        </select>
      </div>

      <div className="date-input-container">
        <p className="date-label">Année</p>
        <select
          className="input date-input font-color1"
          defaultValue={year}
          onChange={(e) => {
            setYear(e.target.value);
          }}
        >
          {Array.from(
            { length: 111 },
            (_, index) => new Date().getFullYear() - 10 - index
          ).map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default BirthdayInput;
