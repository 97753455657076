import SERVER_ADDRESS from "../../aaHoc/global";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const Meals_DeleteModal = ({ meal, handleClose_DeleteModal }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {}, []);
  const handleDeleteButton = () => {
    axios
      .delete(SERVER_ADDRESS + "/api/meals/delete", {
        data: {
          idMeal: meal.id_meal,
        },
      })
      .then((res) => {
        window.location.href = "/meals";
      });
  };

  const handleInactiveStatus = (inactiveStatus) => {
    axios
      .put(SERVER_ADDRESS + "/api/meals/update-inactivestatus", {
        idMeal: meal.id_meal,
        newInactiveStatus: inactiveStatus,
      })
      .then((res) => {
        window.location.href = "/meals";
      });
  };
  if (meal.inactive) {
    handleInactiveStatus(false);
  } else {
    return (
      <>
        <Modal
          fullscreen={true}
          show={show}
          onHide={() => {
            setShow(false);
            handleClose_DeleteModal();
          }}
        >
          <div className="delete-modal-container">
            <Modal.Header closeButton>
              <Modal.Title>
                {" "}
                <h4>
                  Etes-vous sur de vouloir supprimer la recette : "
                  {meal.meal_name}" ?
                </h4>{" "}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="deletemodal-response-container">
                <button
                  onClick={() => handleDeleteButton()}
                  className="btn btn-danger"
                >
                  Oui, la supprimer définitivement.
                </button>
                <button
                  onClick={() => handleInactiveStatus(true)}
                  className="btn btn-warning"
                >
                  Non, je préfère l'archiver pour la récupérer plus tard.
                </button>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </div>
        </Modal>
      </>
    );
  }
};

export default Meals_DeleteModal;
