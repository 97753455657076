import {
  SET_LIST_CHECKEDSHOPPINGLIST,
  SET_DEFAULT,
  SET_NEW,
  SET_BOOL_CONNECTIONERROR,
} from "../action/list.action";

const initialState_WeekmenuNumberOfPeople = {
  dictWeekMenuNumberOfPeople: {},
};
const initialState_WeekmenuNumberOfPeople_New = {
  dictWeekMenuNumberOfPeople_New: {},
};
const initialState_ShoppingList = {
  listCheckedShoppinglist: [],
};

const initialState_ConnectionError = {
  boolConnectionError: false,
};

//export function listReducer_Weekmenu(state = initialState_WeekMenu, action) {
export function reducer_WeekmenuNumberOfPeople(
  state = initialState_WeekmenuNumberOfPeople,
  action
) {
  switch (action.type) {
    case SET_DEFAULT:
      return { dictWeekMenuNumberOfPeople: action.payload };
    default:
      return state;
  }
}
//export function listReducer_Weekmenu(state = initialState_WeekMenu, action) {
export function reducer_WeekmenuNumberOfPeople_New(
  state = initialState_WeekmenuNumberOfPeople_New,
  action
) {
  switch (action.type) {
    case SET_NEW:
      return { dictWeekMenuNumberOfPeople_New: action.payload };
    default:
      return state;
  }
}
export function listReducer_ShoppingList(
  state = initialState_ShoppingList,
  action
) {
  switch (action.type) {
    case SET_LIST_CHECKEDSHOPPINGLIST:
      return { listCheckedShoppinglist: action.payload };
    default:
      return state;
  }
}

export function boolReducer_ConnectionError(
  state = initialState_ConnectionError,
  action
) {
  switch (action.type) {
    case SET_BOOL_CONNECTIONERROR:
      return { boolConnectionError: action.payload };
    default:
      return state;
  }
}
