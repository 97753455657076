import React, { useState } from "react";
import { useAuth } from "../../utils/auth";
import WeekMenu from "../weekmenu/WeekMenu";
import { useSelector } from "react-redux";
import DropdownMenu from "./DropdownMenu";

const Header = () => {
  const auth = useAuth();
  const [displayWeekmenu, setDisplayWeekmenu] = useState(false);

  const dictReducerWeekmenuNumberOfPeople = useSelector(
    (state) => state.reducer_WeekmenuNumberOfPeople.dictWeekMenuNumberOfPeople
  );

  const handleDisplayWeeklyMenu = (display) => {
    setDisplayWeekmenu(display);
    if (display === false) {
      document.body.style.marginTop = "";
    } else {
      document.body.style.marginTop =
        "280px"; /* _settings.scss > navBarHeight + weekmenu.scss > .weekmenu-container > min-height */
    }
  };
  return (
    <div className="header-container">
      <div className="header-bar">
        <div
          className="header-title"
          onClick={() => {
            window.location.href = "/home";
          }}
        >
          <h1>MyWeeklyMenu</h1>
        </div>
        <div
          className="mwm-panel"
          onClick={() => {
            handleDisplayWeeklyMenu(!displayWeekmenu);
          }}
        >
          {displayWeekmenu && auth.jwtToken ? (
            <i className="bi bi-chevron-double-up"></i>
          ) : auth.jwtToken ? (
            <i className="bi bi-chevron-double-down"></i>
          ) : (
            ""
          )}
          <div className="red-bottomright-quantitybadge">
            {Object.keys(dictReducerWeekmenuNumberOfPeople).length !== 0 ? (
              <i
                className={
                  "bi bi-" +
                  Object.keys(dictReducerWeekmenuNumberOfPeople).length +
                  "-circle-fill"
                }
              ></i>
            ) : (
              ""
            )}
          </div>
        </div>

        {auth.jwtToken ? (
          <div
            onClick={() => {
              handleDisplayWeeklyMenu(false);
            }}
          >
            <DropdownMenu />
          </div>
        ) : (
          ""
        )}
      </div>
      {displayWeekmenu ? <WeekMenu /> : ""}
    </div>
  );
};

export default Header;
