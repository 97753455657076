import SERVER_ADDRESS from "../../aaHoc/global";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../utils/auth";
import axios from "axios";
import { Modal } from "react-bootstrap";
import ScrollToTop from "../../aaHoc/ScrollToTop";
import Header from "../header/Header";
import BirthdayInput from "./BirthdayInput";

const Subscribe = () => {
  const auth = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState("");

  const { shareToken } = useParams();

  const [panelIndex, setPanelIndex] = useState(1);

  /* MANDATORY */
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmed, setPasswordConfirmed] = useState("");
  const [confirmationError, setConfirmationError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [connectionError, setConnectionError] = useState(false);
  const [connected, setConnected] = useState(false);

  const minPassLength = 6;
  const [passLengthError, setPassLengthError] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmed, setShowPasswordConfirmed] = useState(false);
  /* OPTIONNAL */
  const [firstname, setFirstname] = useState(null);
  const [lastname, setLastname] = useState(null);
  const [birthday, setBirthday] = useState(null);

  const handleUpdateBirthday = (birthdayDate) => {
    const formattedDate = `${birthdayDate.getFullYear()}-${(
      birthdayDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${birthdayDate.getDate().toString().padStart(2, "0")}`;
    setBirthday(formattedDate);
  };
  function ValidateEmail(input) {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (validRegex.test(input)) {
      return true;
    } else {
      return false;
    }
  }

  const handleSubscribe = async () => {
    let errorSubscribe = false;
    if (password === passwordConfirmed) {
      setConfirmationError(false);
    } else {
      setConfirmationError(true);
      errorSubscribe = true;
    }

    if (
      password.length >= minPassLength &&
      passwordConfirmed.length >= minPassLength
    ) {
      setPassLengthError(false);
    } else {
      setPassLengthError(true);
      errorSubscribe = true;
    }

    if (ValidateEmail(email)) {
      setEmailError(false);
    } else {
      setEmailError(true);
      errorSubscribe = true;
    }

    if (!errorSubscribe) {
      await axios
        .post(SERVER_ADDRESS + "/api/user/create", {
          email,
          password,
          firstname,
          lastname,
          birthday,
        })
        .then((res) => {
          if (res.status === 200) {
            setConnectionError(false);
            setShowModal(true);
            setModalText(
              "Vous est désormais inscrit. Bienvenue sur MyWeeklyMenu ! Vous allez être redirigé vers la page principale"
            );
          } else {
            setShowModal(true);
            setModalText("Une erreur s'est produite. Veuillez réessayer");
            setConnectionError(true);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 409) {
            setShowModal(true);
            setModalText(
              "Un compte existe déjà à cette adresse. Connectez-vous ou utilisez la fonction 'Mot de passe oublié'"
            );
            setConnectionError(true);
          } else {
            setShowModal(true);
            setModalText("Une erreur s'est produite. Veuillez réessayer");
            setConnectionError(true);
          }
        });
      if (!connectionError) {
        setConnected(await auth.login({ email, password, shareToken }));
      }
    }
  };

  function handleCloseModal() {
    setShowModal(false);
    if (connected) {
      window.location.href = "/subscribe-success";
    }
  }

  return (
    <div className="page-component login-page">
      <ScrollToTop />
      <Header />
      <div className="login-container">
        <div className="subscribe-input">
          <h1 className="centered">S'inscrire</h1>
          <div className="divider"></div>
          {panelIndex === 1 ? (
            <div>
              <p className="subscribe-label centered">Prénom & Nom</p>
              <input
                type="text"
                className="input"
                placeholder="Prénom"
                onChange={(e) =>
                  setFirstname(e.target.value.replace(/"/g, "'"))
                }
                value={firstname}
              />
              <input
                type="text"
                className="input"
                placeholder="Nom"
                onChange={(e) => setLastname(e.target.value.replace(/"/g, "'"))}
                value={lastname}
              />
              <p className="subscribe-label centered">Date de naissance</p>
              <BirthdayInput handleUpdateBirthday={handleUpdateBirthday} />
              <div className="subscribe-area">
                <div
                  className="rounded-shorttext-button back-color2 font-color0 centered font-bold alldir-shadows"
                  onClick={() => {
                    setPanelIndex(2);
                  }}
                >
                  Suivant
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {panelIndex === 2 ? (
            <div>
              <p className="subscribe-label centered">Email</p>
              <input
                type="text"
                className="input"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value.replace(/"/g, "'"))}
                value={email}
              />
              {emailError && (
                <p className="error-message">
                  L'adresse email est invalide. Veuillez réessayer.
                </p>
              )}
              <p className="subscribe-label centered">Mot de passe</p>

              <div className="input">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Mot de passe"
                  className="input-password"
                  onChange={(e) =>
                    setPassword(e.target.value.replace(/"/g, "'"))
                  }
                  value={password}
                />
                <i
                  className={
                    showPassword
                      ? "input-password-toggle bi bi-eye-slash-fill"
                      : "input-password-toggle bi bi-eye-fill"
                  }
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                ></i>
              </div>
              <div className="input">
                <input
                  type={showPasswordConfirmed ? "text" : "password"}
                  placeholder="Confirmez le mot de passe"
                  className="input-password"
                  onChange={(e) =>
                    setPasswordConfirmed(e.target.value.replace(/"/g, "'"))
                  }
                  value={passwordConfirmed}
                />
                <i
                  className={
                    showPasswordConfirmed
                      ? "input-password-toggle bi bi-eye-slash-fill"
                      : "input-password-toggle bi bi-eye-fill"
                  }
                  onClick={() => {
                    setShowPasswordConfirmed(!showPasswordConfirmed);
                  }}
                ></i>
              </div>
              {confirmationError && (
                <p className="error-message">
                  Les mots de passe ne sont pas identiques. Veuillez réessayer.
                </p>
              )}
              {passLengthError && (
                <p className="error-message">
                  Votre mot de passe doit faire au moins {minPassLength}{" "}
                  caractères. Veuillez réessayer.
                </p>
              )}
              {connectionError && (
                <div>
                  <p
                    className="password-forget-text"
                    onClick={() => {
                      window.location.href = "/reset";
                    }}
                  >
                    Mot de passe oublié ?
                  </p>
                  <p className="error-message">
                    Une erreur s'est produite. Veuillez réessayer.
                  </p>
                </div>
              )}
              <div className="subscribe-area subscribe-buttons-area">
                <div
                  className="rounded-shorttext-button back-color2 font-color0 centered font-bold alldir-shadows"
                  onClick={() => {
                    handleSubscribe(email, password, passwordConfirmed);
                  }}
                >
                  S'inscrire
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="subscribe-area">
            <p
              className="password-forget-text"
              onClick={() => {
                if (shareToken) {
                  window.location.href = `/login/${shareToken}`;
                } else {
                  window.location.href = `/login`;
                }
              }}
            >
              Déja inscrit ? Se connecter
            </p>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        scrollable={true}
        onHide={() => {
          handleCloseModal();
        }}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p>{modalText}</p>
          <div
            className="info-modal-closebutton btn btn-success"
            onClick={() => {
              handleCloseModal();
            }}
          >
            Fermer
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Subscribe;
