import SERVER_ADDRESS from "../../aaHoc/global";
import axios from "axios";
import React, { useState } from "react";

const ShareFamily_Create = () => {
  const [panelIndex, setPanelIndex] = useState(1);
  const [familyName, setFamilyName] = useState("");
  const [familyDescription, setFamilyDescription] = useState("");
  const handleNextClick = () => {
    if (panelIndex < 3) {
      setPanelIndex(panelIndex + 1);
    } else {
      axios
        .post(SERVER_ADDRESS + `/api/family/update`, {
          familyName,
          familyDescription,
        })
        .then((res) => {
          window.location.href = "/share-family";
        });
    }
  };

  const handlePreviousClick = () => {
    setPanelIndex(panelIndex - 1);
  };

  return (
    <div>
      <h1 className="sharefamily-textarea">Créez votre famille</h1>
      <div className="divider"></div>
      {panelIndex === 1 ? (
        <p className="sharefamily-textarea">
          Partager votre profil vous permet de partager l'ensemble de vos
          recettes ainsi que votre liste de courses avec votre coinjoint, coloc,
          ou n'importe qui d'autre. De quoi organiser facilement vos repas de la
          semaine, et faire vos courses efficacement !
        </p>
      ) : (
        ""
      )}
      {panelIndex === 2 ? (
        <p className="sharefamily-textarea">
          Avant de partager, commencons par vous créer une famille. Les membres
          d'une famille partagent la liste des recettes ainsi que la liste de
          courses. Et ils peuvent tous créer de nouvelles recettes ! De quoi
          pouvoir retenir toutes les bonnes idées qui vous passent par la tete
          durant la journée !
        </p>
      ) : (
        ""
      )}
      {panelIndex === 3 ? (
        <div className="createfamily-container">
          <div className="login-input">
            <input
              type="text"
              className="input"
              placeholder="Nom de votre famille"
              onChange={(e) => setFamilyName(e.target.value.replace(/"/g, "'"))}
              value={familyName}
            />
            <textarea
              type="textarea"
              className="input description-input comment-description-input"
              placeholder="Descrivez brièvement cette famille (Facultatif)"
              onChange={(e) =>
                setFamilyDescription(e.target.value.replace(/"/g, "'"))
              }
              value={familyDescription}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="sharefamily-prevnextbuttons-container">
        {panelIndex > 1 ? (
          <div
            className="rounded-shorttext-button back-color2 font-color0 centered font-bold alldir-shadows"
            onClick={() => {
              handlePreviousClick();
            }}
          >
            Précédent
          </div>
        ) : (
          <div></div>
        )}
        <div
          className="rounded-shorttext-button back-color2 font-color0 centered font-bold alldir-shadows"
          onClick={() => {
            handleNextClick();
          }}
        >
          {panelIndex === 3 ? "Créér ma famille" : "Suivant"}
        </div>
      </div>
    </div>
  );
};

export default ShareFamily_Create;
