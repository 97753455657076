import React, { useEffect, useRef, useState } from "react";

const InstallApp_Button = () => {
  const [showInstallButton, setShowInstallButton] = useState(false);
  let installPrompt = null;
  window.addEventListener("beforeinstallprompt", (event) => {
    event.preventDefault();
    installPrompt = event;
  });

  const handleInstallApp = async () => {
    if (!(await installPrompt)) {
      /* alert("Vous avez deja installé MyWeeklyMenu"); */
      return;
    }
    const result = await installPrompt.prompt();
  };
  return (
    <div
      className="rounded-midtext-button font-color0 back-color2 font-bold"
      id="install-android"
      onClick={() => {
        handleInstallApp();
      }}
    >
      Installer l'application (Android)
    </div>
  );
};

export default InstallApp_Button;
