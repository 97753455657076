import SERVER_ADDRESS from "../../aaHoc/global";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setListCheckedShoppinglist,
  setWeekmenuNumberofpeople,
  setBoolConnectionError,
} from "../../action/list.action";
import { Modal } from "react-bootstrap";

import ShoppingList_TableLine from "./ShoppingList_TableLine";
import OfflineMessage from "../../aaHoc/OfflineMessage";
import ShoppingList_RefreshButton from "./buttons/ShoppingList_RefreshButton";

const ShoppingList_Table = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const [shoppingList, setShoppingList] = useState([]);
  const checkedShoppingList = useSelector(
    (state) => state.listReducer_ShoppingList.listCheckedShoppinglist
  );
  const [showPickedItem, setShowPickedItem] = useState(false);

  useEffect(() => {
    /* ATTENTION, quelque chose ne fonctionne pas correctement ici. Lorsqu'on change de page hors connection, les checked ne sont pas retenus */
    axios
      .get(SERVER_ADDRESS + "/api/shoppingList")
      .then((res) => {
        localStorage.setItem("local_shoppingList", JSON.stringify(res.data));
        setShoppingList(res.data);
        const listCheckedShoppingList = res.data
          .filter((item) => item.checked)
          .map((item) => item.id);
        dispatch(setListCheckedShoppinglist(listCheckedShoppingList));
        dispatch(setBoolConnectionError(false));
      })
      .catch((err) => {
        console.log("Hors ligne");
        setShoppingList(JSON.parse(window.localStorage.local_shoppingList));

        if (checkedShoppingList.length === 0) {
          const listCheckedShoppingList = JSON.parse(
            window.localStorage.local_shoppingList
          )
            .filter((item) => item.checked)
            .map((item) => item.id);
          dispatch(setListCheckedShoppinglist(listCheckedShoppingList));
        }
        dispatch(setBoolConnectionError(true));
      });
  }, []);

  useEffect(() => {
    if (
      shoppingList?.length !== 0 &&
      checkedShoppingList?.length === shoppingList?.length
    ) {
      setShowModal(true);
    }
  }, [shoppingList, checkedShoppingList]);

  const handleResetShoppinglist = () => {
    setShowModal(false);
    axios
      .delete(SERVER_ADDRESS + "/api/shoppingList/clear", {
        data: {
          onlyMealsIngredients: false,
        },
      })
      .then((res) => {
        dispatch(setWeekmenuNumberofpeople([]));
        window.localStorage.mealListNumberOfPeople = JSON.stringify({});
        window.localStorage.checkedShoppingList = "";
        axios.put(SERVER_ADDRESS + "/api/weekmenu/clear", {});
        window.location.reload("");
      });
  };

  return (
    <div>
      <OfflineMessage />
      {/* <ShoppingList_RefreshButton /> */}
      <h2 className="section-title-centered">Liste de courses</h2>
      <div className="divider"></div>
      {shoppingList?.length === 0 ? (
        <p className="centered noarticle-shoppinglist">
          <i>Aucun article dans la liste pour le moment</i>
        </p>
      ) : (
        ""
      )}
      {shoppingList?.map((ShoppingItem, index) => {
        if (!checkedShoppingList.includes(ShoppingItem.id)) {
          return (
            <ShoppingList_TableLine
              Strikedthrough={false}
              key={ShoppingItem.id}
              ShoppingItem={ShoppingItem}
            />
          );
        }
      })}
      <div className="pickeditem-container">
        {checkedShoppingList.length > 0 ? (
          <div>
            <div className="pickeditem-header">
              <h2>Dans le caddie</h2>
              <i
                className={
                  showPickedItem ? "bi bi-chevron-up" : "bi bi-chevron-down"
                }
                onClick={() => {
                  setShowPickedItem(!showPickedItem);
                }}
              ></i>
            </div>
            {shoppingList?.map((ShoppingItem, index) => {
              if (
                showPickedItem &&
                checkedShoppingList.includes(ShoppingItem.id)
              ) {
                return (
                  <ShoppingList_TableLine
                    Strikedthrough={true}
                    key={ShoppingItem.id}
                    ShoppingItem={ShoppingItem}
                  />
                );
              }
            })}
          </div>
        ) : (
          ""
        )}
      </div>

      <Modal
        centered
        show={showModal}
        scrollable={true}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Header
          closeButton
          bsPrefix="details-modal-title-area"
        ></Modal.Header>
        <Modal.Body>
          <p className="centered">
            <b>Avez-vous terminé vos courses ?</b>
          </p>
          <p> Souhaitez-vous réinitialiser votre liste de courses ?</p>
        </Modal.Body>
        <Modal.Footer className="confirmmodal-footer centered">
          <button
            className="confirmmodal-button-yes btn btn-warning"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Non
          </button>
          <div
            className="confirmmodal-button-yes btn btn-danger"
            onClick={() => {
              handleResetShoppinglist();
            }}
          >
            Oui
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ShoppingList_Table;
