import SERVER_ADDRESS from "../../aaHoc/global";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import ScrollToTop from "../../aaHoc/ScrollToTop";
import Header from "../header/Header";
import Navigation from "../navigation/Navigation";

const CommentsToDev = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const [OS, setOS] = useState("Android");
  const [commentType, setCommentType] = useState("Nouvelle fonctionnalité");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = () => {
    axios
      .post(SERVER_ADDRESS + "/api/user/comment/create", {
        OS,
        commentType,
        subject,
        description,
      })
      .then((res) => {
        if (res.status === 200) {
          setShowModal(true);
        } else {
          alert("Une erreur s'est produite. Veuillez réessayer");
        }
      });
  };
  return (
    <div className="page-component commentstodev-container">
      <ScrollToTop />
      <Header />
      <Navigation />
      <h1>Donner son avis</h1>
      <div className="divider"></div>
      <p>
        <i>
          Merci de prendre le temps de nous donner un retour. L'application est
          encore en cours de développement et chaque retour des utilisateurs est
          primordial !
        </i>
      </p>
      <label>
        <b>Systéme d'exploitation</b>
      </label>
      <select
        className="input select-input font-color1"
        onChange={(e) => {
          setOS(e.target.value);
        }}
      >
        <option value="Android">Android</option>
        <option value="iOS">iOS (iPhone)</option>
        <option value="Windows">Windows</option>
      </select>
      <label>
        <b>Type de commentaire</b>
      </label>
      <select
        className="input select-input font-color1"
        onChange={(e) => {
          setCommentType(e.target.value);
        }}
      >
        <option value="Nouvelle fonctionnalité">Nouvelle fonctionnalité</option>
        <option value="Bug">Bug</option>
        <option value="Divers">Divers</option>
        <option value="Divers">Coucou petite perruche !</option>
      </select>
      <label>
        <b>Sujet</b>
      </label>
      <input
        type="text"
        className="input recipename-input"
        placeholder="Sujet"
        value={subject}
        onChange={(e) => {
          setSubject(e.target.value.replace(/"/g, "'"));
        }}
      />
      <label>
        <b>Commentaire</b>
      </label>
      <textarea
        type="textarea"
        className="input description-input comment-description-input"
        placeholder="Un peu plus de détails"
        value={description}
        onChange={(e) => setDescription(e.target.value.replace(/"/g, "'"))}
      />
      <div
        className="rounded-midtext-button back-color2 font-color0 centered font-bold alldir-shadows"
        onClick={() => {
          handleSubmit();
        }}
      >
        Envoyer mon commentaire
      </div>
      <Modal
        show={showModal}
        scrollable={true}
        onHide={() => {
          setShowModal(false);
          navigate("/meals");
        }}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p>
            Merci pour ton commentaire ! Nous analyserons cela au plus vite !
          </p>
          <div
            className="info-modal-closebutton btn btn-success"
            onClick={() => {
              navigate("/meals");
            }}
          >
            Fermer
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CommentsToDev;
