import React, { useState } from "react";
import ShoppingList_AddItemModal from "../ShoppingList_AddItemModal";

const ShoppingList_AddItemButton = () => {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
    window.location.reload();
  };

  return (
    <div className="button-additem-container bottomright-floatingbutton-container">
      <i
        onClick={() => {
          setShowModal(true);
        }}
        className="button-additem bi bi-plus-lg"
      ></i>
      <span className="mealdetails-container">
        {showModal && <ShoppingList_AddItemModal handleClose={handleClose} />}
      </span>
    </div>
  );
};

export default ShoppingList_AddItemButton;
