export const SET_DEFAULT = "SET_DEFAULT"
export const SET_NEW = "NEW"
export const SET_LIST_CHECKEDSHOPPINGLIST = "SET_LIST_CHECKEDSHOPPINGLIST"
export const SET_BOOL_CONNECTIONERROR = "SET_BOOL_CONNECTIONERROR"

export const setWeekmenuNumberofpeople = (dict) => {
    return (dispatch) => {
        return dispatch({ type: SET_DEFAULT, payload: dict })
    }
}
export const setWeekmenuNumberofpeople_New = (dict) => {
    return (dispatch) => {
        return dispatch({ type: SET_NEW, payload: dict })
    }
}
export const setListCheckedShoppinglist = (list) => {
    return (dispatch) => {
        return dispatch({ type: SET_LIST_CHECKEDSHOPPINGLIST, payload: list })
    }
}

export const setBoolConnectionError = (bool) => {
    return (dispatch) => {
        return dispatch({ type: SET_BOOL_CONNECTIONERROR, payload: bool })
    }
}