import SERVER_ADDRESS from "../../aaHoc/global";

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Meals_DeleteModal from "./Meals_DeleteModal";
const Meals_Details = ({ meal, handleClose }) => {
  const navigate = useNavigate();

  const [mealListIngredients, setMealListIngredients] = useState([]);
  const [recipeDescription, setRecipeDescription] = useState();
  const [show, setShow] = useState(true);
  const dictReducerWeekmenuNumberOfPeople_New = useSelector(
    (state) =>
      state.reducer_WeekmenuNumberOfPeople_New.dictWeekMenuNumberOfPeople_New
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleClose_DeleteModal = () => {
    setShowDeleteModal(false);
  };
  const handleDeleteButton = () => {
    axios
      .delete(SERVER_ADDRESS + "/api/meals/delete", {
        data: {
          idMeal: meal.id_meal,
        },
      })
      .then((res) => {
        window.location.href = "/meals";
      });
  };

  let lngWeekmenuNumberOfPeopleUpdated = meal.number_of_people;
  let lngWeekmenuNumberOfPeopleMultiplier = 1;
  if (meal.id_meal in dictReducerWeekmenuNumberOfPeople_New) {
    lngWeekmenuNumberOfPeopleUpdated =
      dictReducerWeekmenuNumberOfPeople_New[meal.id_meal];
    lngWeekmenuNumberOfPeopleMultiplier =
      dictReducerWeekmenuNumberOfPeople_New[meal.id_meal] /
      meal.number_of_people;
  }
  useEffect(() => {
    navigate("/meals/viewModal");
    window.onpopstate = function (e) {
      e.preventDefault();
      handleClose();
    };
    window.onpageshow = function (e) {
      if (e.persisted) {
        e.preventDefault();
        handleClose();
      }
    };
    async function getMealData() {
      try {
        const res = await axios.get(
          SERVER_ADDRESS + `/api/meals/view/${meal.id_meal}`
        );
        setMealListIngredients(res.data.recipeListIngredients);
        setRecipeDescription(res.data.recipeInfo.meal_description);
      } catch (error) {
        console.error(error);
      }
    }
    getMealData();
  }, []);
  return (
    <>
      <Modal
        dialogClassName="mealsdetails-modal"
        show={show}
        scrollable={true}
        onHide={() => {
          setShow(false);
          handleClose();
        }}
      >
        <Modal.Header closeButton bsPrefix="details-modal-title-area">
          <h1>{meal.meal_name}</h1>
          <div className="divider"></div>
        </Modal.Header>
        <Modal.Body>
          <div className="meal-infos">
            <div className="description-area">
              <h2>Description:</h2>
              <pre wrap="hard" className="description-text-area">
                {recipeDescription}
              </pre>
            </div>
            <h2>
              Ingredients{" "}
              {"(" +
                lngWeekmenuNumberOfPeopleUpdated +
                (lngWeekmenuNumberOfPeopleUpdated === 1
                  ? " personne)"
                  : " personnes)")}
              :
            </h2>
            <div className="meal-ingredient">
              {mealListIngredients &&
                mealListIngredients.map((ingredient, index) => (
                  <ul key={ingredient.ingredient_name}>
                    {Math.round(
                      ingredient.quantity *
                        lngWeekmenuNumberOfPeopleMultiplier *
                        100
                    ) /
                      100 +
                      " " +
                      (ingredient.unit_measure === "Portion(s)"
                        ? ""
                        : ingredient.unit_measure) +
                      " " +
                      ingredient.ingredient_name}
                  </ul>
                ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="centered">
          <span className="deleteModal-container" id={meal.meal_name}>
            {showDeleteModal && (
              <Meals_DeleteModal
                key={meal.id_meal}
                meal={meal}
                handleClose_DeleteModal={handleClose_DeleteModal}
              />
            )}
          </span>
          {meal.inactive ? (
            <button
              className="button-delete btn btn-danger"
              onClick={() => {
                handleDeleteButton();
              }}
            >
              Supprimer
            </button>
          ) : (
            ""
          )}
          <button
            className={
              meal.inactive
                ? "button-delete btn btn-warning"
                : "button-delete btn btn-danger"
            }
            onClick={() => {
              setShowDeleteModal(true);
            }}
          >
            {meal.inactive ? "Réactiver" : "Supprimer"}
          </button>
          <div
            className="button-update btn btn-success"
            onClick={() =>
              (window.location.href = "/create-recipe/" + meal.id_meal)
            }
          >
            Modifier
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Meals_Details;
