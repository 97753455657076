import React from "react";

import Home_Navigation from "./Home_Navigation";
import Home_Divider from "./Home_Divider";
import Home_Howitworks from "./Home_Howitworks";
import Home_Whyusing from "./Home_Whyusing";
import Home_Landingarea from "./Home_Landingarea";

const Home = () => {
  return (
    <div className="home-page font-handwritten">
      <Home_Navigation />
      <Home_Landingarea />
      <Home_Whyusing />
      <section className="blank-section-background"></section>
      <Home_Howitworks />
      <footer>
        <div className="footer-container">
          © 2023 Copyright Charles Hals. All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default Home;
