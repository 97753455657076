import SERVER_ADDRESS from "../../aaHoc/global";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Meals_TableLine from "./Meals_TableLine";
import { useDispatch, useSelector } from "react-redux";
import {
  setBoolConnectionError,
  setWeekmenuNumberofpeople,
} from "../../action/list.action";

import OfflineMessage from "../../aaHoc/OfflineMessage";

const Meals_Table = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [mealList, setMealList] = useState([]);
  const [displayEmptyMessage, setDisplayEmptyMessage] = useState(true);

  const dictReducerWeekmenuNumberOfPeople = useSelector(
    (state) => state.reducer_WeekmenuNumberOfPeople.dictWeekMenuNumberOfPeople
  );
  const dictReducerWeekmenuNumberOfPeople_New = useSelector(
    (state) =>
      state.reducer_WeekmenuNumberOfPeople_New.dictWeekMenuNumberOfPeople_New
  );

  let dictWeekmenuQuantityMultiplier = {};
  let dictWeekmenuQuantityUpdated = {};
  Object.keys(dictReducerWeekmenuNumberOfPeople).forEach((key) => {
    if (dictReducerWeekmenuNumberOfPeople_New[key] !== undefined) {
      dictWeekmenuQuantityMultiplier[key] =
        dictReducerWeekmenuNumberOfPeople_New[key] /
        dictReducerWeekmenuNumberOfPeople[key];
      dictWeekmenuQuantityUpdated[key] =
        dictReducerWeekmenuNumberOfPeople_New[key];
    } else {
      dictWeekmenuQuantityMultiplier[key] = 1;
      dictWeekmenuQuantityUpdated[key] = dictReducerWeekmenuNumberOfPeople[key];
    }
  });
  const handleGenerateShoppingList = (
    dictWeekmenuQuantityUpdated,
    dictWeekmenuQuantityMultiplier
  ) => {
    async function clearAndUpdateShoppingList() {
      try {
        await axios.delete(SERVER_ADDRESS + "/api/shoppingList/clear", {
          data: {
            onlyMealsIngredients: true,
          },
        });

        const response = await axios.post(
          SERVER_ADDRESS + "/api/shoppingList/create",
          {
            dictWeekMenu: dictWeekmenuQuantityMultiplier,
          }
        );
        window.localStorage.mealListNumberOfPeople = JSON.stringify({});
        window.localStorage.checkedShoppingList = "";
        window.location.href = "/shopping-list";
      } catch (error) {
        console.error(error);
      }
    }

    if (Object.keys(dictReducerWeekmenuNumberOfPeople).length !== 0) {
      axios.put(SERVER_ADDRESS + "/api/weekmenu/update", {
        dictWeekMenu: dictWeekmenuQuantityUpdated,
      });
      clearAndUpdateShoppingList();
    } else {
      axios
        .delete(SERVER_ADDRESS + "/api/shoppingList/clear", {
          data: {
            onlyMealsIngredients: true,
          },
        })
        .then((res) => {
          dispatch(setWeekmenuNumberofpeople([]));
          window.localStorage.mealListNumberOfPeople = JSON.stringify({});
          window.localStorage.mealListNumberOfPeople_New = JSON.stringify({});
          window.localStorage.checkedShoppingList = "";
          axios.put(SERVER_ADDRESS + "/api/weekmenu/clear", {});
          window.location.reload("");
        });
    }
  };

  useEffect(() => {
    axios
      .get(SERVER_ADDRESS + "/api/meals")
      .then((res) => {
        setMealList(res.data);
        dispatch(setBoolConnectionError(false));
      })
      .catch((err) => {
        dispatch(setBoolConnectionError(true));
      });
  }, []);

  useEffect(() => {
    if (mealList?.length === 0) {
      setDisplayEmptyMessage(true);
    } else {
      setDisplayEmptyMessage(false);
    }
  }, [mealList]);
  return (
    <div>
      <div className="recipelist-container">
        <OfflineMessage />
        <h2 className="section-title-centered">Mes recettes</h2>
        <div className="divider"></div>
        {displayEmptyMessage ? (
          <div>
            <p className="centered">
              <i>Tu n'as pour le moment aucune recette.</i>
            </p>
            <p className="centered">
              <i>
                Utilise le bouton ci-dessous pour créer ta première recette en
                quelques instants !
              </i>
            </p>
            <p className="centered">
              <i>
                Tu pourras ensuite générer la liste de course automatiquement en
                fonction de ce que tu as mis dans ta recette !
              </i>
            </p>
          </div>
        ) : (
          ""
        )}
        {mealList
          ?.filter((meal) => meal.inactive !== 1)
          ?.map((meal, index) => (
            <Meals_TableLine key={meal.id_meal} meal={meal} />
          ))}
        <div
          className="squared-midtext-button back-color2 font-color0 centered font-bold alldir-shadows mealstable-createrecipe-button"
          onClick={() => {
            navigate("/create-recipe");
          }}
        >
          Créer une nouvelle recette
        </div>
        {mealList.filter((meal) => meal.inactive === 1).length > 0 ? (
          <div>
            <h2 className="section-title-centered">Recettes archivées</h2>
            <div className="divider"></div>
            {mealList
              ?.filter((meal) => meal.inactive === 1)
              ?.map((meal, index) => (
                <Meals_TableLine key={meal.id_meal} meal={meal} />
              ))}
          </div>
        ) : (
          ""
        )}
      </div>
      {Object.keys(dictReducerWeekmenuNumberOfPeople).length === 0 ? (
        ""
      ) : (
        <button
          onClick={() => {
            handleGenerateShoppingList(
              dictWeekmenuQuantityUpdated,
              dictWeekmenuQuantityMultiplier
            );
          }}
          className="second-bottom-banner"
        >
          {"Generer la liste de courses (" +
            Object.keys(dictReducerWeekmenuNumberOfPeople).length +
            ")"}
        </button>
      )}
    </div>
  );
};

export default Meals_Table;
