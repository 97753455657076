import React from "react";
import { useSelector } from "react-redux";

const OfflineMessage = () => {
  const connectionError = useSelector(
    (state) => state.boolReducer_ConnectionError.boolConnectionError
  );

  return (
    <div>
      {connectionError ? (
        <div>
          <p style={{ marginBottom: "0px" }} className="error-message centered">
            Attention, vous êtes hors connection.
          </p>
          <p style={{ marginBottom: "0px" }} className="error-message centered">
            Vos données ne seront pas synchronisées
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default OfflineMessage;
