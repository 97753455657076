let SERVER_ADDRESS = "http://localhost:8000";

const getServerAddress = () => {
  if (window.location.href.includes("https://myweeklymenu.com")) {
    SERVER_ADDRESS = "https://myweeklymenu.com";
  } else if (window.location.href.includes("https://www.myweeklymenu.com")) {
    SERVER_ADDRESS = "https://www.myweeklymenu.com";
  }
};
getServerAddress();
export default SERVER_ADDRESS;
