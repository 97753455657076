import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../utils/auth";
import Header from "../header/Header";
import ScrollToTop from "../../aaHoc/ScrollToTop";

const Login = () => {
  const auth = useAuth();
  const { shareToken } = useParams();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const connected = await auth.login({ email, password, shareToken });
    if (connected) {
      window.location.href = "/shopping-list";
    }
  };
  if (auth.userData?.idUser) {
    window.location.href = "/shopping-list";
  }
  return (
    <div className="page-component login-page">
      <ScrollToTop />
      <Header />
      <div className="login-container">
        <div>
          <div className="login-input">
            <h1 className="centered">Se connecter</h1>
            <div className="divider"></div>
            <input
              type="text"
              className="input"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value.replace(/"/g, "'"))}
              value={email}
            />
            <div className="input">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Mot de passe"
                className="input-password"
                onChange={(e) => setPassword(e.target.value.replace(/"/g, "'"))}
                value={password}
              />
              <i
                className={
                  showPassword
                    ? "input-password-toggle bi bi-eye-slash-fill"
                    : "input-password-toggle bi bi-eye-fill"
                }
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              ></i>
            </div>
            {auth.connectionError && (
              <div>
                <p className="error-message">
                  Un problème est survenu. Vérifiez votre email et/ou votre mot
                  de passe
                </p>

                <p
                  className="password-forget-text"
                  onClick={() => {
                    window.location.href = "/reset";
                  }}
                >
                  Mot de passe oublié ?
                </p>
              </div>
            )}
          </div>
          <div className="login-button-area">
            <div
              className="rounded-shorttext-button back-color2 font-color0 font-bold centered alldir-shadows"
              onClick={handleSubmit}
            >
              Se connecter
            </div>
          </div>
          <div className="subscribe-area">
            <p
              className="password-forget-text"
              onClick={() => {
                if (shareToken) {
                  window.location.href = `/subscribe/${shareToken}`;
                } else {
                  window.location.href = `/subscribe`;
                }
              }}
            >
              Pas encore de compte ? S'inscrire
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
