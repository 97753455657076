import React from "react";
import { useAuth } from "./auth";
import { Navigate, useLocation } from "react-router-dom";

const RequireAuth = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();
  if (!auth.jwtToken) {
    auth.logout();
    return <Navigate to="/subscribe" state={{ path: location.pathname }} />;
  }
  return children;
};

export default RequireAuth;
