import SERVER_ADDRESS from "../../aaHoc/global";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../utils/auth";
import Header from "../header/Header";
import ScrollToTop from "../../aaHoc/ScrollToTop";

const JoinFamily = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { shareToken } = useParams();

  const [familyName, setFamilyName] = useState("");
  const [familyDescription, setfamilyDescription] = useState("");
  useEffect(() => {
    axios.get(SERVER_ADDRESS + `/api/family/${shareToken}`).then((res) => {
      setFamilyName(res.data.family_name);
      setfamilyDescription(res.data.family_description);
    });
  }, []);

  const handleJoinFamily = () => {
    if (auth.jwtToken) {
      axios
        .post(SERVER_ADDRESS + `/api/family/join`, {
          shareToken,
        })
        .then((res) => {
          localStorage.setItem("jwt_token", res.data.jwtToken);
          window.location.href = "/meals";
        });
    } else {
      navigate(`/subscribe/${shareToken}`);
    }
  };
  return (
    <div className="page-component">
      <ScrollToTop />
      <Header />
      <h1 className="joinfamily-header">
        Vous avez été invité à rejoindre la famille :
      </h1>
      <h1 className="joinfamily-header">
        <b>{familyName}</b>
      </h1>
      <div className="divider"></div>
      <div className="joinfamily-joinbutton-container">
        <div
          className="rounded-midtext-button back-color2 font-color0 centered font-bold alldir-shadows"
          onClick={() => {
            handleJoinFamily();
          }}
        >
          Rejoindre la famille
        </div>
        {auth.jwtToken ? (
          <p className="disclaimer-joinfamily">
            Attention, vous perdrez vos recettes existantes.<br></br>{" "}
            Envoyez-nous un commentaire si vous souhaitez les récupérer.
          </p>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default JoinFamily;
