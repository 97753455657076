import SERVER_ADDRESS from "../../aaHoc/global";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CreateRecipe_Form_IngredientLine from "./CreateRecipe_Form_IngredientLine";
import { setBoolConnectionError } from "../../action/list.action";
import { useDispatch } from "react-redux";
import OfflineMessage from "../../aaHoc/OfflineMessage";

const CreateRecipe_Form = () => {
  const dispatch = useDispatch();
  const [numberOfIngredients, setNumberOfIngredients] = useState(6);
  const [recipeName, setRecipeName] = useState("");
  const [recipeDescription, setRecipeDescription] = useState("");
  const [numberOfPeople, setNumberOfPeople] = useState(2);
  const [listRecipeIngredients, setListRecipeIngredients] = useState([]);

  const [listIngredientsDictDB, setListIngredientsDictDB] = useState([]);
  const [listAvailableIngredientsDB, setListAvailableIngredientsDB] = useState(
    []
  );

  const [missingName, setMissingName] = useState(false);
  const [missingIngredient, setMissingIngredient] = useState(false);
  const [missingNumberOfPeople, setMissingNumberOfPeople] = useState(false);

  const [isUpdatePage, setIsUpdatePage] = useState(false);
  const [idMeal, setIdMeal] = useState();

  const { id } = useParams();

  useEffect(() => {
    axios
      .get(SERVER_ADDRESS + "/api/ingredients")
      .then((res) => {
        setListIngredientsDictDB(res.data);
        setListAvailableIngredientsDB(
          res.data.map((item) => item.ingredient_name)
        );
        dispatch(setBoolConnectionError(false));
      })
      .catch((error) => {
        dispatch(setBoolConnectionError(true));
      });

    if (id !== undefined) {
      setIsUpdatePage(true);
      setIdMeal(id);
      axios.get(SERVER_ADDRESS + "/api/meals/view/" + id).then((res) => {
        setRecipeName(res.data.recipeInfo.meal_name);
        setRecipeDescription(res.data.recipeInfo.meal_description);
        setNumberOfPeople(res.data.recipeInfo.meal_numberOfPeople);
        setListRecipeIngredients(res.data.recipeListIngredients);
        if (res.data.recipeListIngredients.length > numberOfIngredients) {
          setNumberOfIngredients(res.data.recipeListIngredients.length);
        }
      });
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (recipeName === "") {
      setMissingName(true);
    } else if (
      listRecipeIngredients.length === 0 ||
      listRecipeIngredients[0].ingredient_name === undefined
    ) {
      setMissingIngredient(true);
    } else if (numberOfPeople === "") {
      setMissingNumberOfPeople(true);
    } else {
      setMissingName(false);
      setMissingIngredient(false);
      setMissingNumberOfPeople(false);
      const updatedListIngredient =
        listRecipeIngredients /* A METTRE DANS LE BACKEND */
          .filter(
            (item) => item !== undefined && item.ingredient_name !== undefined
          )
          .map(async (item) => {
            let updatedItem = item;
            if (item.id_unit_measure === undefined) {
              updatedItem = {
                ...updatedItem,
                id_unit_measure: "1",
              };
            }
            if (
              listAvailableIngredientsDB.includes(item.ingredient_name.trim())
            ) {
              updatedItem = {
                ...updatedItem,
                id_ingredient: listIngredientsDictDB.find(
                  (id) =>
                    id.ingredient_name.trim() === item.ingredient_name.trim()
                ).id,
              };
            } else {
              const res = await axios
                .post(SERVER_ADDRESS + "/api/ingredients/create", {
                  ingredientName: item.ingredient_name.trim(),
                })
                .then((res) => {
                  // PAS SUR DE LA MODIF ICI. PEUT POSER PROBLEME
                  updatedItem = {
                    ...updatedItem,
                    id_ingredient: res.data,
                  };
                });
            }
            return updatedItem;
          });

      Promise.all(updatedListIngredient)
        .then((updatedIngredients) => {
          let url = "";
          if (isUpdatePage) {
            url = SERVER_ADDRESS + "/api/meals/update";
          } else {
            url = SERVER_ADDRESS + "/api/meals/create";
          }
          axios
            .post(url, {
              idMeal,
              recipeName,
              recipeDescription,
              numberOfPeople,
              listRecipeIngredients: updatedIngredients,
            })
            .then((res) => {
              setIdMeal(res.data);
              window.location.href = "/create-recipe/" + res.data;
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleAdd = (e) => {
    e.preventDefault();
    setNumberOfIngredients(numberOfIngredients + 1);
  };

  const handleUpdateListRecipeIngredients = (index, dictIngredient) => {
    const updatedListIngredient = [...listRecipeIngredients];
    updatedListIngredient[index] = {
      ...(updatedListIngredient[index] = dictIngredient),
    };
    setListRecipeIngredients(updatedListIngredient);
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <OfflineMessage />
      <button
        className="second-bottom-banner"
        onClick={(e) => {
          handleSubmit(e);
        }}
      >
        {isUpdatePage ? "Mettre à jour" : "Créer la recette"}
      </button>
      <div className="form-container">
        <input
          type="text"
          className="input recipename-input"
          placeholder="Nom de la recette"
          value={recipeName}
          onChange={(e) => {
            if (e.target.value === "") {
              setMissingName(true);
              setRecipeName(e.target.value.replace(/"/g, "'"));
            } else {
              setMissingName(false);
              setRecipeName(e.target.value.replace(/"/g, "'"));
            }
          }}
        />
        {missingName && (
          <p style={{ color: "red" }}>Un nom de recette est requis</p>
        )}
        <div className="divider"></div>
        <textarea
          type="textarea"
          className="input description-input"
          placeholder="Description"
          value={recipeDescription}
          onChange={(e) => {
            if (e.target.value.length < 3000) {
              setRecipeDescription(e.target.value.replace(/"/g, "'"));
            } else {
              console.log("Too long text");
            }
          }}
        />
        <div className="nop-input-area">
          <label>Nombre de personnes : </label>
          <input
            type="number"
            className="input nop-input"
            placeholder="Nombre de personnes"
            value={numberOfPeople}
            onChange={(e) =>
              setNumberOfPeople(e.target.value.replace(/"/g, "'"))
            }
          />
          {missingNumberOfPeople && (
            <p style={{ color: "red" }}>Le nombre de personnes est requis</p>
          )}
        </div>
        <h2>Liste d'ingrédients</h2>

        {missingIngredient && (
          <p style={{ color: "red" }}>Au moins un ingrédient est requis</p>
        )}
        <div>
          <>
            {Array.from(
              { length: numberOfIngredients },
              (_, index /* TODO: CREER UN COMPONENT */) => (
                <CreateRecipe_Form_IngredientLine
                  key={index}
                  index={index}
                  listRecipeIngredients={listRecipeIngredients[index]}
                  handleUpdateListRecipeIngredients={
                    handleUpdateListRecipeIngredients
                  }
                />
              )
            )}
          </>
        </div>
        <div className="ingredients-buttons">
          <div
            onClick={(e) => {
              handleAdd(e);
            }}
            className="plus-ingredient-button bi bi-plus-square-fill"
          />
        </div>
      </div>
    </form>
  );
};

export default CreateRecipe_Form;
